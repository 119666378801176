import { Middleware } from 'redux';

import endpoints from '../../config/endpoints';
import { isSongData, isSongIdData } from '../../types/song.type';
import { fetchData } from '../api/api.actions';
import { removeLoader } from '../loaders/loaders.slice';
import { showNotification } from '../notifications/notifications.actions';
import {
  requestSongs,
  fetchSongsSuccess,
  fetchSongsFail,
  PLAYLIST,
  requestSingleSong,
  fetchSingleSongsSuccess,
  fetchSingleSongsFail,
} from './playlist.actions';
import { addSong, clearSongs, setCurrentSongId } from './playlist.slice';

const playlistMiddleware: Middleware = (store) => (next) => (action) => {
  const { dispatch } = store;
  const { type, payload, meta } = action;

  next(action);

  switch (type) {
    case requestSongs.toString():
      dispatch(clearSongs());
      dispatch(
        fetchData(
          {
            request: {
              method: 'GET',
              url: endpoints.songs.list,
            },
            actionSuccess: fetchSongsSuccess.toString(),
            actionError: fetchSongsFail.toString(),
          },
          { entity: PLAYLIST }
        )
      );
      break;
    case requestSingleSong.toString():
      dispatch(
        fetchData(
          {
            request: {
              method: 'GET',
              url: endpoints.songs.single(payload),
            },
            actionSuccess: fetchSingleSongsSuccess.toString(),
            actionError: fetchSingleSongsFail.toString(),
          },
          { ...meta, entity: PLAYLIST }
        )
      );
      break;
    case fetchSongsSuccess.toString():
      if (isSongIdData(payload)) {
        payload.forEach((item, index) =>
          dispatch(requestSingleSong(item, payload.length - index - 1))
        );
      }
      break;
    case fetchSingleSongsSuccess.toString():
      if (isSongData(payload)) {
        dispatch(addSong(payload));
      }

      if (meta.count === 0) {
        dispatch(removeLoader(PLAYLIST));
        dispatch(setCurrentSongId(1));
      }
      break;
    case fetchSongsFail.toString():
      //notification
      dispatch(removeLoader(PLAYLIST));
      dispatch(
        showNotification({
          type: 'error',
          text: `Błąd podczas ładowania piosenek: ${payload.code}, ${payload.message}`,
        })
      );
      break;
  }
};

export default playlistMiddleware;
