export default function exponentialScale(x: number) {
  //@ts-ignore
  //TODO: delete window.appSettings
  return Math.pow(0.5, (window.appSettings?.expValue || 7.5) * (1 - x));
}

export function logarithmicScale(x: number) {
  //@ts-ignore
  return Math.log2(x) / (window.appSettings?.expValue || 7.5) + 1;
}
