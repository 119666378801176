import styles from './header.module.scss';

import Heading from '../atoms/heading';
import LogoList from '../molecules/logo-list';
import MapWidget, { MapWidgetProps } from '../atoms/map-widget';

export type HeaderProps = {
  mapWidgetProps?: MapWidgetProps;
};

export default function Header({ mapWidgetProps }: HeaderProps) {
  return (
    <header className={styles.header}>
      <Heading />
      <LogoList className={styles.logoList} />
      <MapWidget {...mapWidgetProps} className={styles.mapWidget} />
    </header>
  );
}
