import styles from './big-progress-button.module.scss';

import ToggleButton, { ToggleButtonProps } from '../atoms/toggle-button';
import CircleProgress from '../atoms/circle-progress';

export type BigProgressButtonProps = {
  min?: number;
  max?: number;
  progress?: number;
} & Omit<ToggleButtonProps, 'activeClass' | 'activeChildren'>;

export default function BigProgressButton({
  children,
  onClick = () => {},
  className = '',
  min = 0,
  max = 100,
  progress = 0,
  value,
  disabled,
}: BigProgressButtonProps) {
  return (
    <ToggleButton
      className={`${styles.bigProgressButton} ${className}`}
      activeClass={styles.active}
      onClick={onClick}
      value={value}
      disabled={disabled}
      noInnerWrapper
    >
      <>
        {children}
        <CircleProgress min={min} max={max} progress={progress} className={styles.progress} />
      </>
    </ToggleButton>
  );
}
