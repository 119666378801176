import { combineReducers } from 'redux';

import playlistReducer from './playlist/playlist.slice';
import loadersReducer from './loaders/loaders.slice';
import playerReducer from './player/player.slice';
import notificationsReducer from './notifications/notifications.slice';
import volumeControllerReducer from './volume-controller/volume-controller.slice';

const rootReducer = combineReducers({
  playlist: playlistReducer,
  loaders: loadersReducer,
  player: playerReducer,
  notifications: notificationsReducer,
  volumeController: volumeControllerReducer,
});

export default rootReducer;
