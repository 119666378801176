import { useCallback, useEffect, useRef } from 'react';

import styles from './interactive-map.module.scss';
import { ReactComponent as MapSVG } from '../../assets/img/map.svg';

const MAP_POINT_REGEXP = /Ellipse_([0-9]+)/g;

type CallbackMap = Record<string, Record<string, (...args: any) => any>>;

export type InteractiveMapProps = {
  onMouseOver?: (id: number) => void;
  onClick?: (id: number) => void;
  value?: number;
};

export default function InteractiveMap({
  onMouseOver = () => {},
  value,
  onClick = () => {},
}: InteractiveMapProps) {
  const ref = useRef<SVGSVGElement>(null);

  const moveSelectedPoint = useCallback((point?: Element) => {
    const selectedPointRef = ref.current?.querySelector('.map__selected-point');

    if (point) {
      const pointTransform = point.getAttribute('transform');

      if (pointTransform) {
        selectedPointRef?.setAttribute('transform', pointTransform);
      }
    } else {
      selectedPointRef?.setAttribute('transform', '');
    }
  }, []);

  const createCallbackMap = useCallback(
    (pointsRef: Element[]) => {
      return pointsRef.reduce<CallbackMap>((acc, point) => {
        const callbacks = {
          mouseenter: () => onMouseOver(getPointId(point)),
          click: () => onClick(getPointId(point)),
        };

        return {
          ...acc,
          [point.id]: callbacks,
        };
      }, {});
    },
    [onMouseOver, onClick]
  );

  useEffect(() => {
    const pointsRefNodes = ref.current?.querySelectorAll('.map__point');
    const selectedPointRef = ref.current?.querySelector('.map__selected-point');

    if (pointsRefNodes) {
      const pointsRef = Array.from(pointsRefNodes);

      const callbackMap = createCallbackMap(pointsRef);

      mapCallbackMapToFunction(callbackMap, pointsRef, 'addEventListener');

      const handleSelectedPointClick = () => onClick(value || 0);
      const handleSelectedPointMouseLeave = () => onMouseOver(0);

      if (selectedPointRef) {
        selectedPointRef.addEventListener('click', handleSelectedPointClick);
        selectedPointRef.addEventListener('mouseleave', handleSelectedPointMouseLeave);
      }

      return () => {
        mapCallbackMapToFunction(callbackMap, pointsRef, 'removeEventListener');

        if (selectedPointRef) {
          selectedPointRef.removeEventListener('click', handleSelectedPointClick);
          selectedPointRef.removeEventListener('mouseleave', handleSelectedPointMouseLeave);
        }
      };
    }
  }, [createCallbackMap, onMouseOver, onClick, value]);

  useEffect(() => {
    const pointsRefNodes = ref.current?.querySelectorAll('.map__point');

    if (pointsRefNodes) {
      const pointsRef = Array.from(pointsRefNodes);

      const point = pointsRef?.find((point) => point.id === `Ellipse_${value}`);

      if (point) {
        moveSelectedPoint(point);
      } else if (value === 0) {
        moveSelectedPoint();
      }
    }
  }, [value, moveSelectedPoint]);

  return <MapSVG ref={ref} className={styles.interactiveMap} />;
}

const getPointId = (point?: Element) => {
  return parseInt(point ? new RegExp(MAP_POINT_REGEXP).exec(point.id)?.[1] || '0' : '0');
};

const mapCallbackMapToFunction = (
  callbackMap: CallbackMap,
  pointsRef: Element[],
  functionKey: 'addEventListener' | 'removeEventListener'
) => {
  pointsRef.forEach((point) => {
    Object.entries(callbackMap[point.id]).forEach(([eventName, callback]) => {
      point[functionKey](eventName, callback);
    });
  });
};
