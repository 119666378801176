const volumeControllerConfig = {
  fadeTime: [180, 540], //seconds
  intervalTime: [180000, 540000], //mseconds
  layerValueRange: [
    [0, 0.3],
    [0.3, 0.6],
    [0.6, 1],
    [0.6, 1],
  ],
};

export default volumeControllerConfig;
