import styles from './logo-list.module.scss';
import sacrumProfanum from '../../assets/img/sacrum_profanum.png';
import kbf from '../../assets/img/kbf.png';
import krakow from '../../assets/img/krakow.png';
import warszawa from '../../assets/img/warszawa.png';

export type LogoListProps = {
  className?: string;
};

export default function LogoList({ className }: LogoListProps) {
  return (
    <div className={`${styles.logoList} ${className}`}>
      <img className={styles.logo} src={krakow} alt="krakow" />
      <img className={styles.logo} src={kbf} alt="kbf" />
      <img className={styles.logo} src={sacrumProfanum} alt="sacrum profanum" />
      <img className={styles.logo} src={warszawa} alt="warszawa" />
    </div>
  );
}
