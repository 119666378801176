import { MouseEventHandler, TouchEventHandler, useCallback, useRef } from 'react';

import useFancyHover from './use-fancy-hover';

export type UseSliderProps = {
  min: number;
  max: number;
  onChange: (value: number) => void;
  direction?: 'horizontal' | 'vertical';
  disabled?: boolean;
};

type DirectionPropertyKey = 'horizontal' | 'vertical';

type DirectionPropertiesMap = {
  client: Record<DirectionPropertyKey, `client${'X' | 'Y'}`>;
  offset: Record<DirectionPropertyKey, `offset${'X' | 'Y'}`>;
  position: Record<DirectionPropertyKey, 'x' | 'y'>;
  size: Record<DirectionPropertyKey, 'width' | 'height'>;
};

const DIRECTION_PROPERTIES_MAP: DirectionPropertiesMap = {
  client: {
    horizontal: 'clientX',
    vertical: 'clientY',
  },
  offset: {
    horizontal: 'offsetX',
    vertical: 'offsetY',
  },
  position: {
    horizontal: 'x',
    vertical: 'y',
  },
  size: {
    horizontal: 'width',
    vertical: 'height',
  },
};

const setHtmlHiddenOverflow = (isHidden: boolean) => {
  const htmlElement = document.querySelector('html');

  if (htmlElement) {
    htmlElement.style.overflowY = isHidden ? 'hidden' : '';
  }
};

export default function useSlider<T extends HTMLElement>({
  min,
  max,
  onChange,
  direction = 'horizontal',
  disabled,
}: UseSliderProps) {
  const parentRect = useRef<DOMRect>();
  const position = useRef(0);
  const lastMousePosition = useRef(0);
  const { ref, ...fancyHoverData } = useFancyHover<T>({
    colors: ['var(--color-secondary)', 'var(--color-secondary-lighter)'],
  });

  const touchMoveEventToMouseEvent = useCallback((event: TouchEvent) => {
    return new MouseEvent('mousemove', {
      clientX: event.changedTouches[0].clientX,
      clientY: event.changedTouches[0].clientY,
      screenX: event.changedTouches[0].screenX,
      screenY: event.changedTouches[0].screenY,
      altKey: event.altKey,
      bubbles: event.bubbles,
      cancelable: event.cancelable,
      composed: event.composed,
      ctrlKey: event.ctrlKey,
      detail: event.detail,
      metaKey: event.metaKey,
      view: event.view,
    });
  }, []);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      let parentSize = 0;
      let parentPos = 0;
      const offset = event[DIRECTION_PROPERTIES_MAP.client[direction]] - lastMousePosition.current;

      if (parentRect.current) {
        parentSize = parentRect.current[DIRECTION_PROPERTIES_MAP.size[direction]];
        parentPos = parentRect.current[DIRECTION_PROPERTIES_MAP.position[direction]];
      }

      position.current += offset;
      lastMousePosition.current = event[DIRECTION_PROPERTIES_MAP.client[direction]];

      if (
        position.current > 0 &&
        position.current <= parentSize &&
        parentRect?.current &&
        event[DIRECTION_PROPERTIES_MAP.client[direction]] > parentPos - 1 &&
        event[DIRECTION_PROPERTIES_MAP.client[direction]] <= parentPos + parentSize + 2
      ) {
        onChange((position.current / parentSize) * (max - min) + min);
      }
    },
    [onChange, max, min, direction]
  );

  const handleTouchMove = useCallback(
    (event: TouchEvent) => {
      handleMouseMove(touchMoveEventToMouseEvent(event));
    },
    [handleMouseMove, touchMoveEventToMouseEvent]
  );

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    setHtmlHiddenOverflow(false);
  }, [handleMouseMove]);

  const handleTouchEnd = useCallback(() => {
    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchEnd);
    setHtmlHiddenOverflow(false);
  }, [handleTouchMove]);

  const handleMouseDown: MouseEventHandler = useCallback(
    (event) => {
      parentRect.current = event.currentTarget.getBoundingClientRect();
      position.current = event.nativeEvent[DIRECTION_PROPERTIES_MAP.offset[direction]];
      lastMousePosition.current = event[DIRECTION_PROPERTIES_MAP.client[direction]];

      handleMouseMove(event.nativeEvent);

      setHtmlHiddenOverflow(true);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [handleMouseMove, handleMouseUp, direction]
  );

  const handleTouchStart: TouchEventHandler = useCallback(
    (event) => {
      parentRect.current = event.currentTarget.getBoundingClientRect();
      lastMousePosition.current =
        event.changedTouches[0][DIRECTION_PROPERTIES_MAP.client[direction]];
      position.current =
        lastMousePosition.current -
        parentRect.current[DIRECTION_PROPERTIES_MAP.position[direction]];

      handleTouchMove(event.nativeEvent);

      setHtmlHiddenOverflow(true);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    },
    [direction, handleTouchMove, handleTouchEnd]
  );

  if (disabled) {
    return {
      thumbRef: ref,
      style: {},
    };
  }

  return {
    onMouseDown: handleMouseDown,
    onTouchStart: handleTouchStart,
    thumbRef: ref,
    ...fancyHoverData,
  };
}
