import { useCallback } from 'react';

import styles from './map-song-list-element.module.scss';
import { SongListElementProps } from './song-list-element';

export type MapSongListElementProps = {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
} & SongListElementProps;

export default function MapSongListElement({
  active,
  children,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
  disabled,
  tooltip,
}: MapSongListElementProps) {
  const handleClick = useCallback(() => {
    if (!disabled && onClick) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <li
      className={`${styles.mapSongListElement} ${active ? styles.active : ''} ${
        disabled ? styles.disabled : ''
      } ${className}`}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      title={tooltip}
    >
      {children}
    </li>
  );
}
