import React from 'react';

export type ModalContextType = {
  modals: Record<string, React.ReactElement>;
  addModal: (modal: React.ReactElement) => void;
  removeModal: (id: string) => void;
};

const defaultModalContext = {
  modals: {},
  addModal: () => {},
  removeModal: () => {},
};

const ModalContext = React.createContext<ModalContextType>(defaultModalContext);

export { ModalContext as default, defaultModalContext };
