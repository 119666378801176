import { Middleware } from 'redux';
import _ from 'lodash';

import { getIsAuto, getIsPlaying, getPlayers } from '../player/player.selectors';
import { setAuto, setPlayersVolume } from '../player/player.slice';
import { getCount, getIntervalID } from './volume-controller.selectors';
import {
  nextCount,
  setFadeTimeEntity,
  setIntervalID,
  setIntervalTime,
} from './volume-controller.slice';
import volumeControllerConfig from '../../config/_volume-controller';
import { createAllPlayersAction } from '../player/player.actions';
import randomizeBetweenValues from '../../utility/randomizeBetweenValues';

const volumeControllerMiddleware: Middleware = (state) => (next) => (action) => {
  const { getState, dispatch } = state;

  next(action);

  const isPlaying = getIsPlaying(getState());
  const isAuto = getIsAuto(getState());
  const intervalID = getIntervalID(getState());

  if (isPlaying && isAuto) {
    if (intervalID === null) {
      const callback = () => {
        const players = getPlayers(getState());
        const count = getCount(getState());
        const playingPlayers = players.filter((player) => player.isPlaying);
        const shuffledRanges = _.shuffle(volumeControllerConfig.layerValueRange);
        const intervalTime = Math.floor(
          randomizeBetweenValues(volumeControllerConfig.intervalTime)
        );

        dispatch(setIntervalTime(intervalTime));
        dispatch(
          createAllPlayersAction(setPlayersVolume, playingPlayers, (player, index) => {
            const fadeTimeRange =
              intervalTime !== 0
                ? [volumeControllerConfig.fadeTime[0], intervalTime / 1000]
                : volumeControllerConfig.fadeTime;
            dispatch(
              setFadeTimeEntity({
                [player.name]: randomizeBetweenValues(fadeTimeRange),
              })
            );

            if (player.name === 'A' && count % 2 === 0) {
              return 0.001;
            }

            return randomizeBetweenValues(shuffledRanges[index]);
          })
        );
        dispatch(setIntervalID(setTimeout(callback, intervalTime)));
        dispatch(nextCount());
      };

      dispatch(setIntervalID(setTimeout(callback, 0)));
    }
  } else if (intervalID !== null) {
    clearInterval(intervalID);
    dispatch(setIntervalID(null));

    if (isAuto) {
      dispatch(setAuto(false));
    }
  }
};

export default volumeControllerMiddleware;
