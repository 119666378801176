import { useEffect, useRef } from 'react';

import styles from './background-video.module.scss';
import breakpoints from '../../config/breakpoints';
import useBreakpoint from '../../hooks/use-breakpoint';
import poster from '../../assets/img/poster.jpg';

const VIDEO_ENABLED = process.env.NODE_ENV === 'production';

export default function BackgroundVideo() {
  const isPhone = useBreakpoint(breakpoints.phone);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (VIDEO_ENABLED && videoRef.current) {
      if (isPhone) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  }, [isPhone]);

  return (
    <video
      ref={videoRef}
      className={styles.backgroundVideo}
      autoPlay={VIDEO_ENABLED}
      muted
      loop
      poster={poster}
    >
      <source src="/data/video/background.mp4" type="video/mp4" />
    </video>
  );
}
