import { ReactElement, CSSProperties } from 'react';

import useFancyHover from '../../hooks/use-fancy-hover';

import styles from './button.module.scss';

export type ButtonProps = {
  children?: string | ReactElement;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  noInnerWrapper?: boolean;
  noHover?: boolean;
  tooltip?: string;
};

export default function Button({
  children,
  className = '',
  onClick,
  style,
  disabled,
  noInnerWrapper = false,
  noHover = false,
  tooltip,
}: ButtonProps) {
  const {
    ref,
    style: hoverStyle,
    ...handlers
  } = useFancyHover<HTMLButtonElement>({
    colors: ['var(--color-gray)', 'var(--color-primary-lighter-opacity-1)'],
  });

  const extraProps = noHover ? {} : handlers;

  return (
    <button
      ref={ref}
      style={noHover ? style : { ...style, ...hoverStyle }}
      className={`${styles.button} ${className}`}
      onClick={onClick}
      disabled={disabled}
      title={tooltip}
      {...extraProps}
    >
      {noInnerWrapper ? children : <div className={styles.innerWrapper}>{children}</div>}
    </button>
  );
}
