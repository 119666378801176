import styles from './map-button.module.scss';

import Button, { ButtonProps } from './button';

export default function MapButton({ children, className = '', ...props }: ButtonProps) {
  return (
    <div className={styles.mapButtonWrapper}>
      <Button className={`${styles.mapButton} ${className}`} noInnerWrapper {...props}>
        M
      </Button>
      {children && <p className={styles.text}>{children}</p>}
    </div>
  );
}
