import React, { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import MixerContext from '../../contexts/mixer.context';
import ModalContext, { defaultModalContext, ModalContextType } from '../../contexts/modal.context';
import Mixer from '../../utility/mixer';

export default function withGlobalContext<P>(WrappedComponent: React.FC<P>) {
  return function GlobalContext(props: P) {
    const [modals, setModals] = useState<ModalContextType['modals']>(defaultModalContext.modals);
    const [mixerContext] = useState(new Mixer(new AudioContext()));

    const addModal: ModalContextType['addModal'] = useCallback((modal) => {
      setModals((modals) => ({
        ...modals,
        [uuid()]: modal,
      }));
    }, []);

    const removeModal: ModalContextType['removeModal'] = useCallback((id) => {
      setModals((modals) => {
        const newModals = { ...modals };
        delete newModals[id];
        return newModals;
      });
    }, []);

    const handleClick = () => {
      // if (!mixerContext) {
      //   setMixerContext(new Mixer(new AudioContext()));
      // }
      mixerContext.resumeAudioContext();
    };

    return (
      <ModalContext.Provider value={{ modals, addModal, removeModal }}>
        <MixerContext.Provider value={mixerContext}>
          <WrappedComponent onClick={handleClick} {...props} />
        </MixerContext.Provider>
      </ModalContext.Provider>
    );
  };
}
