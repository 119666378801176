import styles from './footer-element.module.scss';

export type FooterElementProps = {
  title: string;
  content: string;
  reverse?: boolean;
  noStyling?: boolean;
  className?: string;
};

export default function FooterElement({
  title,
  content,
  reverse,
  noStyling,
  className,
}: FooterElementProps) {
  return (
    <div className={getClassNames({ reverse, noStyling, className })}>
      <p className={styles.title}>{title}</p>
      <p className={styles.content}>{content}</p>
    </div>
  );
}

const getClassNames = ({
  reverse,
  noStyling,
  className = '',
}: Pick<FooterElementProps, 'reverse' | 'noStyling' | 'className'>) => {
  return `${styles.footerElement} ${reverse ? styles.reverse : ''} ${
    noStyling ? styles.noStyling : ''
  } ${className}`;
};
