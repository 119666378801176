import styles from './media-player-controller.module.scss';
import PlayerController from '../../types/player-controller.type';
import useMediaPlayer from '../../hooks/use-media-player';

import Slider from '../atoms/slider';
import BigProgressButton from './big-progress-button';
import translations from '../../locales/pl.json';

export type MediaPlayerControllerProps = {
  className?: string;
  data: PlayerController;
  name: string;
};

export default function MediaPlayerController({
  className = '',
  data,
  name,
}: MediaPlayerControllerProps) {
  const {
    audioProps,
    isPlaying,
    position,
    duration,
    setPlaying,
    setVolume,
    volume,
    canPlay,
    isAuto,
  } = useMediaPlayer(data.name);

  const volumeDisplayValue = Math.floor(volume ? volume * 100 : 0).toString();

  return (
    <div className={`${styles.mediaPlayerController} ${className}`}>
      <audio {...audioProps} />
      <BigProgressButton
        className={styles.bigProgressButton}
        value={isPlaying}
        onClick={setPlaying}
        progress={position}
        max={duration}
        disabled={!canPlay}
      >
        {volumeDisplayValue}
      </BigProgressButton>
      <p className={styles.volume}>{volumeDisplayValue}</p>
      <Slider
        className={styles.slider}
        value={volume}
        onChange={setVolume}
        disabled={isAuto}
        tooltip={isAuto ? translations['tooltip.slider.disabled'] : undefined}
      />
      <p className={styles.label}>{name}</p>
    </div>
  );
}
