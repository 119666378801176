import { Middleware } from 'redux';
import { v4 as uuid } from 'uuid';

import { showNotification } from './notifications.actions';
import { addNotification, removeNotification } from './notifications.slice';

const notificationsMiddleware: Middleware = (state) => (next) => (action) => {
  const { dispatch } = state;
  const { type, payload } = action;

  next(action);

  switch (type) {
    case showNotification.toString():
      const id = uuid();
      dispatch(addNotification({ id, type: payload.type, text: payload.text }));
      setTimeout(() => dispatch(removeNotification({ id })), 5000);
      break;
  }
};

export default notificationsMiddleware;
