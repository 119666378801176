import { RootState } from '../store';

export const getSongs = (state: RootState) => state.playlist.items;

export const getCurrentSongId = (state: RootState) => state.playlist.current;

export const getCurrentSong = (state: RootState) => {
  const currentId = getCurrentSongId(state);

  const songs = getSongs(state);

  if (currentId !== null && songs.length > 0) {
    return songs.find((song) => song.id === currentId);
  }
};

export const isSelected = (state: RootState) => state.playlist.current !== null;

export const getSongNames = (state: RootState) => getSongs(state).map((item) => item.name);
