import Button from './button';
import styles from './song-list-element.module.scss';

export type SongListElementProps = {
  children?: string;
  className?: string;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
  tooltip?: string;
};

export default function SongListElement({
  children = '',
  className = '',
  disabled = false,
  active = false,
  onClick = () => {},
  tooltip,
}: SongListElementProps) {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <li className={`${styles.songListElement} ${className}`}>
      <Button
        onClick={handleClick}
        className={`${styles.button} ${active ? styles.active : ''} ${
          disabled ? styles.disabled : ''
        }`}
        noInnerWrapper
        noHover={disabled}
        tooltip={tooltip}
      >
        {children}
      </Button>
    </li>
  );
}
