import styles from './footer.module.scss';
import authors from '../../config/authors';

import FooterElement from '../atoms/footer/footer-element';
import PersonsList from '../molecules/persons-list';

export default function Footer() {
  return (
    <div className={styles.footer}>
      <PersonsList data={authors} className={styles.left} column="left" />
      <PersonsList data={authors} className={styles.right} column="right">
        <div>
          <FooterElement
            title="-"
            content="Projekt został zrealizowany w ramach stypendium twórczego m.st. Warszawy"
            noStyling
            className={styles.element}
          />
          <FooterElement
            title="-"
            content="copyright Łukasz Zięba / 2021"
            noStyling
            className={styles.element}
          />
        </div>
      </PersonsList>
    </div>
  );
}
