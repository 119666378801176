import apiMiddleware from './api/api.middleware';
import notificationsMiddleware from './notifications/notifications.middleware';
import playerMiddleware from './player/player.middleware';
import playlistMiddleware from './playlist/playlist.middleware';
import volumeControllerMiddleware from './volume-controller/volume-controller.middleware';

const middlewares = [
  apiMiddleware,
  playlistMiddleware,
  playerMiddleware,
  notificationsMiddleware,
  volumeControllerMiddleware,
];

export default middlewares;
