import React, {
  CSSProperties,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export type UseFancyHoverProps<T> = {
  ref?: React.RefObject<T>;
  useVanillaJS?: boolean;
  colors: [string, string];
};

export default function useFancyHover<T extends HTMLElement>({
  ref: externalRef,
  useVanillaJS = false,
  colors = ['black', 'grey'],
}: UseFancyHoverProps<T>) {
  const [colorStart, colorEnd] = colors;
  const [style, setStyle] = useState<CSSProperties>({});
  const ref = useRef<T>(null);
  const elementRef = externalRef ? externalRef : ref;

  const elementWidth = useRef(0);
  const elementHeight = useRef(0);

  const animateFancyHover = useCallback(
    (offsetX: number, offsetY: number) => {
      const x = offsetX - elementWidth.current / 2;
      const y = elementHeight.current / 2 - offsetY;

      const angle = -radToDeg(Math.atan2(y, x)) + 90;
      const length = Math.sqrt(x * x + y * y);
      const gradientPosition = 50 + (length / elementWidth.current) * 100;

      return `linear-gradient(${angle}deg, ${colorStart} 0%, ${colorEnd} ${gradientPosition}%)`;
    },
    [colorStart, colorEnd]
  );

  const handleMouseMove: MouseEventHandler = useCallback(
    ({ nativeEvent }) => {
      const { offsetX, offsetY } = nativeEvent;

      setStyle({
        background: animateFancyHover(offsetX, offsetY),
      });
    },
    [animateFancyHover]
  );

  const handleMouseLeave: MouseEventHandler = useCallback(() => {
    setStyle({});
  }, []);

  useEffect(() => {
    const element = elementRef?.current;

    const vanillaJSMouseMove = ({ offsetX, offsetY }: MouseEvent) => {
      if (useVanillaJS && element) {
        element.style.background = animateFancyHover(offsetX, offsetY);
      }
    };

    const vanillaJSMouseLeave = () => {
      if (useVanillaJS && element) {
        element.style.background = '';
      }
    };

    if (useVanillaJS) {
      element?.addEventListener('mousemove', vanillaJSMouseMove);
      element?.addEventListener('mouseleave', vanillaJSMouseLeave);
    }

    return () => {
      if (useVanillaJS) {
        element?.removeEventListener('mousemove', vanillaJSMouseMove);
        element?.removeEventListener('mouseleave', vanillaJSMouseLeave);
      }
    };
  }, [elementRef, useVanillaJS, animateFancyHover]);

  useEffect(() => {
    elementWidth.current = elementRef?.current?.offsetWidth || 0;
    elementHeight.current = elementRef?.current?.offsetHeight || 0;
  }, [elementRef]);

  return {
    ref,
    onMouseMove: !useVanillaJS ? handleMouseMove : undefined,
    onMouseLeave: !useVanillaJS ? handleMouseLeave : undefined,
    style: !useVanillaJS ? style : undefined,
  };
}

const radToDeg = (rad: number) => {
  return (rad * 180.0) / Math.PI;
};
