import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './toolbar.module.scss';
import { getIsPlaying, getPlayers } from '../../redux/player/player.selectors';
import { setPlayersPlaying } from '../../redux/player/player.slice';
import { getCurrentSongId } from '../../redux/playlist/playlist.selectors';
import { ReactComponent as PlayIcon } from '../../assets/img/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/img/pause.svg';

import ToggleButton, { ToggleButtonProps } from '../atoms/toggle-button';
import { createAllPlayersAction } from '../../redux/player/player.actions';
import AutoButton from '../atoms/toolbar/auto-button';
import ShuffleButton from '../atoms/toolbar/shuffle-button';

export type ToolbarProps = {
  className?: string;
};

export default function Toolbar({ className = '' }: ToolbarProps) {
  const isPlaying = useSelector(getIsPlaying);
  const isNotItemSelected = useSelector(getCurrentSongId) === null;
  const players = useSelector(getPlayers);
  const dispatch = useDispatch();

  const setIsPlaying: ToggleButtonProps['onClick'] = useCallback(
    (value) => dispatch(createAllPlayersAction(setPlayersPlaying, players, () => value)),
    [dispatch, players]
  );

  return (
    <div className={`${styles.toolbar} ${className}`}>
      <ToggleButton
        className={styles.button}
        onClick={setIsPlaying}
        value={isPlaying}
        disabled={isNotItemSelected}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </ToggleButton>
      {isPlaying ? (
        <AutoButton className={styles.button} />
      ) : (
        <ShuffleButton className={styles.button} disabled={isNotItemSelected} />
      )}
    </div>
  );
}
