import { browserName } from 'react-device-detect';

import styles from './not-supported-modal.module.scss';

import Modal, { ModalProps } from '../templates/modal';
import Button from '../atoms/button';

export type NotSupportedModalProps = Omit<ModalProps, 'children'>;

export default function NotSupportedModal({ onClose, ...props }: NotSupportedModalProps) {
  return (
    <div className={styles.notSupportedModalWrapper}>
      <Modal className={styles.notSupportedModal} {...props}>
        <h4 className={styles.title}>Uwaga!</h4>
        <p className={styles.text}>
          Przeglądarka, której używasz - <strong>{browserName}</strong> może niepoprawnie obsługiwać
          niektóre z funkcji oferowanych w niniejszej aplikacji. Zalecamy skorzystać z jednej z
          wymienionych przelgądarek: <strong>Google Chrome</strong>, <strong>Opera</strong>,{' '}
          <strong>Microsoft Edge</strong>, <strong>Brave</strong>, aby w pełni skorzystać z
          możliwości tej aplikacji.
        </p>
        <Button className={styles.button} noInnerWrapper onClick={onClose}>
          Rozumiem
        </Button>
      </Modal>
    </div>
  );
}
