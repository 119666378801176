export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

const endpoints = {
  songs: {
    list: '/data/songs/index.json',
    single: (id: string) => `/data/songs/${id}/index.json`,
    media: (name: string, layer: string, ext: string) => `/data/songs/${name}/${layer}.${ext}`,
  },
};

export default endpoints;
