import { playersConfig } from '../config/_players';
import { PlayerItem } from '../redux/player/player.types';
import { linearChangeAudioParam } from './exponential-change-audio-param';
import exponentialScale, { logarithmicScale } from './exponential-scale';
import { fadeIn, fadeOut } from './fades';
import Mixer from './mixer';

export type OnVolumeChangeCallback = (value: number) => void;

class MediaPlayer {
  private audioContext;
  private htmlAudioElement;
  private mediaElementNode;
  private gainNode;
  private isAuto = false;
  private isPlaying = false;
  private volume = 1;
  private fadeTime = 0;
  private onVolumeChangeCallback: OnVolumeChangeCallback | null = null;
  private volumeCheckInterval: NodeJS.Timeout | null = null;

  constructor(mixerContext: Mixer, audioElement: HTMLAudioElement | null) {
    this.audioContext = mixerContext.audioContext;

    if (audioElement) {
      this.htmlAudioElement = audioElement;
      this.htmlAudioElement.loop = true;

      this.mediaElementNode = this.audioContext.createMediaElementSource(this.htmlAudioElement);
      this.gainNode = this.audioContext.createGain();

      this.mediaElementNode.connect(this.gainNode);
      this.gainNode.connect(mixerContext.inputNode);
    }
  }

  change(playerDiff: Partial<PlayerItem>) {
    Object.entries(playerDiff).forEach(([key, value]) => {
      if (key === 'src' && typeof value === 'string') {
        if (this.isPlaying) {
          this.changeSrcSmoothly(value);
        } else {
          this.setSrc(value);
        }
      } else if (key === 'volume' && typeof value === 'number') {
        this.setVolume(value);
      } else if (key === 'isPlaying' && typeof value === 'boolean') {
        this.setPlaying(value);
      } else if (key === 'position' && typeof value === 'number') {
        this.setPosition(value);
      }
    });
  }

  setIsAuto(value: boolean) {
    this.isAuto = value;

    if (value) {
      this.createCheckVolumeInterval();
    } else {
      this.clearCheckVolumeInterval();
    }
  }

  setFadeTime(value: number) {
    this.fadeTime = value;
  }

  private setSrc(value: string) {
    if (this.htmlAudioElement) {
      this.htmlAudioElement.src = value;
    }
  }

  private setVolume(value: number) {
    this.volume = value < 0.1 ? 0 : exponentialScale(value);

    this.gainNode?.gain.cancelScheduledValues(this.audioContext.currentTime);

    linearChangeAudioParam({
      audioParam: this.gainNode?.gain,
      value: this.volume,
      time: this.isAuto ? this.fadeTime : playersConfig.shortFade,
      audioContext: this.audioContext,
    });

    if (this.onVolumeChangeCallback) {
      this.onVolumeChangeCallback(this.getCurrentVolume());
    }
  }

  onVolumeChange(callback: (value: number) => void) {
    this.onVolumeChangeCallback = callback;
  }

  private async setPlaying(value: boolean) {
    if (value) {
      await this.play();
      this.isPlaying = true;
    } else {
      await this.pause();
      this.isPlaying = false;
    }
  }

  private setPosition(value: number) {
    if (this.htmlAudioElement) {
      this.htmlAudioElement.currentTime = value;
    }
  }

  private async play(fadeTime?: number) {
    // if (this.audioContext.state !== 'running') {
    //   await this.audioContext.resume();
    // }
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fadeIn({
          audioParam: this.gainNode?.gain,
          // value: player?.volume || 1,
          value: this.volume,
          time: fadeTime || playersConfig.mediumFade,
          beforeEnd: () => {
            this.htmlAudioElement?.play();
            resolve();
          },
          audioContext: this.audioContext,
        });
      }, 0);
    });
  }

  private pause(fadeTime?: number) {
    return new Promise<void>((resolve) => {
      fadeOut({
        audioParam: this.gainNode?.gain,
        value: 0,
        time: fadeTime || playersConfig.mediumFade,
        beforeEnd: () => {
          this.htmlAudioElement?.pause();
          resolve();
        },
        audioContext: this.audioContext,
      });
    });
  }

  private async changeSrcSmoothly(value: string) {
    await this.pause(playersConfig.longFade);
    this.setSrc(value);
    await this.play(playersConfig.longFade);
  }

  private createCheckVolumeInterval() {
    const callback = () => {
      const currentVolume = this.getCurrentVolume();

      if (this.onVolumeChangeCallback && currentVolume) {
        this.onVolumeChangeCallback(currentVolume);
      }
    };
    this.volumeCheckInterval = setInterval(callback, 250);
    callback();
  }

  private clearCheckVolumeInterval() {
    if (this.volumeCheckInterval) {
      clearInterval(this.volumeCheckInterval);
    }
  }

  getCurrentVolume() {
    const volume = this.gainNode?.gain.value || this.volume;

    if (volume < 0.01) {
      return 0;
    }

    return logarithmicScale(volume);
  }
}

export default MediaPlayer;
