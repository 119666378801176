import breakpoints from '../../config/breakpoints';
import useBreakpoint from '../../hooks/use-breakpoint';

import useSlider from '../../hooks/use-slider';
import styles from './slider.module.scss';

export type SliderProps = {
  min?: number;
  max?: number;
  value?: number;
  onChange?: (value: number) => void;
  className?: string;
  disabled?: boolean;
  tooltip?: string;
};

export default function Slider({
  min = 0,
  max = 1,
  value = 0,
  onChange = () => {},
  className = '',
  disabled,
  tooltip,
}: SliderProps) {
  const isPhone = useBreakpoint(breakpoints.phone);
  const { thumbRef, style, ...handlers } = useSlider<HTMLDivElement>({
    min,
    max,
    onChange,
    direction: isPhone ? 'vertical' : 'horizontal',
    disabled,
  });

  const sliderStyle = {
    ...style,
    [isPhone ? 'height' : 'width']: `${((value - min) / (max - min)) * 100}%`,
  };

  return (
    <div className={`${styles.sliderContainer} ${className}`} title={tooltip}>
      <span className={styles.icon}>-</span>
      <div className={`${styles.slider}  ${disabled ? styles.disabled : ''}`} {...handlers}>
        <div ref={thumbRef} className={styles.thumb} style={sliderStyle}></div>
      </div>
      <input hidden type="range" min={min} max={max} defaultValue={value} />
      <span className={styles.icon}>+</span>
    </div>
  );
}
