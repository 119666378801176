import styles from './toggle-button.module.scss';

import Button, { ButtonProps } from './button';

export type ToggleButtonProps = {
  activeClass?: string;
  activeChildren?: ButtonProps['children'];
  value?: boolean;
  onClick?: (state: boolean) => void;
} & Omit<ButtonProps, 'onClick'>;

export default function ToggleButton({
  activeChildren,
  activeClass = styles.active,
  children,
  className = '',
  onClick = () => {},
  value,
  style,
  disabled,
  noInnerWrapper,
}: ToggleButtonProps) {
  return (
    <Button
      style={style}
      className={`${styles.toggleButton} ${value ? activeClass : ''} ${className}`}
      onClick={() => onClick(!value)}
      disabled={disabled}
      noInnerWrapper={noInnerWrapper}
    >
      {value && activeChildren ? activeChildren : children}
    </Button>
  );
}
