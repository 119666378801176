import { BrowserTypes } from 'react-device-detect';

const browsersNotSupported = [
  BrowserTypes.Firefox,
  BrowserTypes.Safari,
  BrowserTypes.MobileSafari,
  BrowserTypes.Ie,
  BrowserTypes.InternetExplorer,
];

export default browsersNotSupported;
