import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import players from '../../config/players';
import {
  PayloadAllPlayerAction,
  PayloadPlayerAction,
  PlayerItem,
  PlayerState,
} from './player.types';
import { setAllEntitiesParam, setEntityParam, setFlag } from './player.utility';

const createDefaultPlayer = (name: string) => ({
  name,
  src: null,
  position: 0,
  duration: 1,
  volume: 1,
  isPlaying: false,
});

const initialState: PlayerState = {
  isAuto: false,
  players: players.map((item) => createDefaultPlayer(item.name)),
  oldPlayers: players.map((item) => createDefaultPlayer(item.name)),
};

const player = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setAuto(state, action: PayloadAction<boolean>) {
      setFlag(state, 'isAuto', action.payload);
    },
    setPlayersSrc(state, action: PayloadAction<PayloadAllPlayerAction<string>>) {
      setAllEntitiesParam(state.players, action.payload, 'src');
    },
    setPlayerPosition(state, action: PayloadAction<PayloadPlayerAction<number>>) {
      setEntityParam(state.players, action.payload, 'position');
    },
    setPlayersPosition(state, action: PayloadAction<PayloadAllPlayerAction<number>>) {
      setAllEntitiesParam(state.players, action.payload, 'position');
    },
    setPlayerDuration(state, action: PayloadAction<PayloadPlayerAction<number>>) {
      setEntityParam(state.players, action.payload, 'duration');
    },
    setPlayerVolume(state, action: PayloadAction<PayloadPlayerAction<number>>) {
      setEntityParam(state.players, action.payload, 'volume');
    },
    setPlayersVolume(state, action: PayloadAction<PayloadAllPlayerAction<number>>) {
      setAllEntitiesParam(state.players, action.payload, 'volume');
    },
    setPlayerPlaying(state, action: PayloadAction<PayloadPlayerAction<boolean>>) {
      setEntityParam(state.players, action.payload, 'isPlaying');
    },
    setPlayersPlaying(state, action: PayloadAction<PayloadAllPlayerAction<boolean>>) {
      setAllEntitiesParam(state.players, action.payload, 'isPlaying');
    },
    setPlayer(state, action: PayloadAction<PayloadPlayerAction<PlayerItem>>) {
      state.players.splice(
        state.players.findIndex((player) => player.name === action.payload.name),
        1,
        action.payload.value
      );
    },
    updateOldPlayers(state) {
      state.oldPlayers = state.players;
    },
  },
});

export const {
  setAuto,
  setPlayer,
  setPlayerPosition,
  setPlayersPosition,
  setPlayerDuration,
  setPlayersSrc,
  setPlayerVolume,
  setPlayersVolume,
  setPlayerPlaying,
  setPlayersPlaying,
  updateOldPlayers,
} = player.actions;

export default player.reducer;
