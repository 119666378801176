import { Authors } from '../../config/authors';

import FooterElement from '../atoms/footer/footer-element';

export type PersonsListProps = {
  data: Authors[];
  column: Authors['column'];
  children?: React.ReactNode;
  className?: string;
  elementClassName?: string;
};

export default function PersonsList({
  data,
  column,
  children,
  className,
  elementClassName,
}: PersonsListProps) {
  return (
    <div className={className}>
      {data
        .filter((person) => person.column === column)
        .map(({ column, ...person }) => (
          <FooterElement
            key={`person-${column}-${person.title}`}
            {...person}
            className={elementClassName}
          />
        ))}
      {children}
    </div>
  );
}
