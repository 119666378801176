const mediaErrorMessages = {
  [MediaError.MEDIA_ERR_ABORTED]: 'Pobieranie zasobu zostało przerwane przez użytkownika.',
  [MediaError.MEDIA_ERR_NETWORK]:
    'Wystąpił problem z połączeniem sieciowym. Upewnij się, że masz dostęp do Internetu i odśwież stronę.',
  [MediaError.MEDIA_ERR_DECODE]: 'Wystąpił błąd podczas dekodowania strumienia audio.',
  [MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED]:
    'Nie udało się pobrać multimediów. Sprawdź połączenie sieciowe i odśwież stronę.',
};

export default mediaErrorMessages;
