import { useSelector } from 'react-redux';

import styles from './song-list.module.scss';
import SongData from '../../types/song.type';
import { getIsAuto } from '../../redux/player/player.selectors';
import translations from '../../locales/pl.json';

import SongListElement from '../atoms/song-list-element';
import withSongListData from '../hoc/withSongListData';

export type SongListProps = {
  items?: SongData[];
  className?: string;
  value?: number | null;
  onItemClicked?: (index: number) => void;
  loading?: boolean;
};

function SongList({
  items,
  className = '',
  value,
  onItemClicked = () => {},
  loading = false,
}: SongListProps) {
  const isAuto = useSelector(getIsAuto);

  if (loading) {
    return <p>loading</p>;
  }
  return (
    <ul className={`${styles.songList} ${className}`}>
      {items && items.length > 0 ? (
        items.map((item) => (
          <SongListElement
            key={`song-list-element-${item.id}`}
            className={`${styles.songListElement} ${value === item.id ? styles.active : ''}`}
            disabled={isAuto}
            active={value === item.id}
            onClick={() => onItemClicked(item.id)}
            tooltip={isAuto ? translations['tooltip.songListElement.disabled'] : undefined}
          >
            {item.id.toString()}
          </SongListElement>
        ))
      ) : (
        <p>Niestety lista jest pusta!</p>
      )}
    </ul>
  );
}

export default withSongListData(SongList);
