import { useEffect, useRef } from 'react';

import styles from './circle-progress.module.scss';
import { ReactComponent as Progress } from '../../assets/img/progress.svg';

export type CircleProgressProps = {
  min: number;
  max: number;
  progress: number;
  className?: string;
};

export default function CircleProgress({
  min,
  max,
  progress,
  className = '',
}: CircleProgressProps) {
  const progressRef = useRef<SVGSVGElement>(null);
  const circleRef = useRef<HTMLElement | null | undefined>(null);
  const circleMaxStrokeDashoffset = useRef<number>(0);

  useEffect(() => {
    if (!circleRef.current) {
      circleRef.current = progressRef.current?.querySelector('.progress__progress');

      if (circleRef.current) {
        circleMaxStrokeDashoffset.current = parseFloat(
          window.getComputedStyle(circleRef.current).strokeDashoffset
        );
      }
    }
  }, []);

  useEffect(() => {
    const angle = progress >= min ? progress / max : min / max;
    const anglePixels = angle * circleMaxStrokeDashoffset.current;
    const newStrokeDashoffset = circleMaxStrokeDashoffset.current - anglePixels;

    if (circleRef.current) {
      circleRef.current.style.strokeDashoffset = `${newStrokeDashoffset}px`;
    }
  }, [min, max, progress]);

  return <Progress ref={progressRef} className={`${styles.progress} ${className}`} />;
}
