export type ChangeAudioParamProps = {
  audioParam?: AudioParam;
  value: number;
  time: number;
  audioContext: AudioContext;
};

export function exponentialChangeAudioParam({
  audioParam,
  value,
  time,
  audioContext,
}: ChangeAudioParamProps) {
  audioParam?.exponentialRampToValueAtTime(audioParam.value, audioContext.currentTime);
  audioParam?.exponentialRampToValueAtTime(
    value === 0 ? 0.0001 : value,
    audioContext.currentTime + time
  );
}

export function linearChangeAudioParam({
  audioParam,
  value,
  time,
  audioContext,
}: ChangeAudioParamProps) {
  audioParam?.linearRampToValueAtTime(audioParam.value, audioContext.currentTime);
  audioParam?.linearRampToValueAtTime(value, audioContext.currentTime + time);
}
