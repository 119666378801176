import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE: string[] = [];

const loaderSlice = createSlice({
  name: 'loaders',
  initialState: INITIAL_STATE,
  reducers: {
    addLoader(state, action: PayloadAction<string>) {
      if (!state.includes(action.payload)) {
        state.push(action.payload);
      }
    },
    removeLoader(state, action: PayloadAction<string>) {
      state.splice(state.indexOf(action.payload), 1);
    },
  },
});

export const { addLoader, removeLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
