import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationData } from './notifications.types';

const initialState: NotificationData[] = [];

const notifications = createSlice({
  initialState,
  name: 'notifications',
  reducers: {
    addNotification(state, action: PayloadAction<NotificationData>) {
      state.push(action.payload);
    },
    removeNotification(state, action: PayloadAction<Pick<NotificationData, 'id'>>) {
      state.splice(
        state.findIndex((item) => item.id === action.payload.id),
        1
      );
    },
  },
});

export const { addNotification, removeNotification } = notifications.actions;

export default notifications.reducer;
