import { Middleware } from 'redux';

import endpoints from '../../config/endpoints';
import { getCurrentSong } from '../playlist/playlist.selectors';
import { setCurrentSongId } from '../playlist/playlist.slice';
import { createAllPlayersAction, randomPlayersPosition } from './player.actions';
import { getIsAuto, getOldPlayers, getPlayers } from './player.selectors';
import {
  setAuto,
  setPlayerPlaying,
  setPlayersPlaying,
  setPlayersPosition,
  setPlayersSrc,
  updateOldPlayers,
} from './player.slice';
import { PayloadAllPlayerAction, PayloadPlayerAction } from './player.types';
import { arePlayersArrayEqual } from './player.utility';

const playerMiddleware: Middleware = (state) => (next) => (action) => {
  const { type, payload } = action;
  const { dispatch, getState } = state;

  if (
    type !== updateOldPlayers.toString() &&
    type !== setPlayerPlaying.toString() &&
    type !== setPlayersPlaying.toString()
  ) {
    next(action);
  }

  const players = getPlayers(getState());
  const isAuto = getIsAuto(getState());

  switch (type) {
    case setCurrentSongId.toString():
      const currentSong = getCurrentSong(getState());

      if (currentSong) {
        dispatch(
          createAllPlayersAction(setPlayersSrc, players, (player) =>
            endpoints.songs.media(currentSong.name, player.name, currentSong.mediaFormat)
          )
        );
      }
      break;
    case randomPlayersPosition.toString():
      dispatch(
        createAllPlayersAction(setPlayersPosition, players, (player) =>
          Math.floor(Math.random() * player.duration)
        )
      );
      break;
    case updateOldPlayers.toString():
      const oldPlayers = getOldPlayers(getState());
      if (!arePlayersArrayEqual(players, oldPlayers)) {
        next(action);
      }
      break;
    case setPlayerPlaying.toString():
    case setPlayersPlaying.toString():
      const isPlaying = [...players]
        .map((player) => {
          const newPlayer = Array.isArray(payload)
            ? (payload as PayloadAllPlayerAction<boolean>).find((item) => item.name === player.name)
            : (payload as PayloadPlayerAction<boolean>);

          if (newPlayer?.name === player.name) {
            return {
              ...player,
              isPlaying: newPlayer.value,
            };
          }

          return player;
        })
        .some((item) => item.isPlaying);

      if (!isPlaying && isAuto) {
        dispatch(setAuto(false));

        setTimeout(() => {
          next(action);
        }, 50);
      } else {
        next(action);
      }
      break;
  }
};

export default playerMiddleware;
