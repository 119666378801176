import { configureStore } from '@reduxjs/toolkit';

import middlewares from './middleware';
import rootReducer from './reducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: () => middlewares,
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
