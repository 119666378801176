import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FadeTimeEntity, VolumeControllerState } from './volume-controller.types';

const initialState: VolumeControllerState = {
  intervalID: null,
  intervalTime: 0,
  fadeTime: null,
  count: 0,
};

const volumeController = createSlice({
  initialState,
  name: 'volumeController',
  reducers: {
    setIntervalID(state, action: PayloadAction<NodeJS.Timeout | null>) {
      state.intervalID = action.payload;
    },
    setIntervalTime(state, action: PayloadAction<number>) {
      state.intervalTime = action.payload;
    },
    setFadeTimeEntity(state, action: PayloadAction<FadeTimeEntity>) {
      state.fadeTime = {
        ...state.fadeTime,
        ...action.payload,
      };
    },
    nextCount(state) {
      if (state.count === Number.MAX_SAFE_INTEGER) {
        state.count = 0;
      } else {
        state.count++;
      }
    },
  },
});

export default volumeController.reducer;

export const { setIntervalID, setIntervalTime, setFadeTimeEntity, nextCount } =
  volumeController.actions;
