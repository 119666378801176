import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';

import { PayloadAllPlayerAction, PayloadPlayerAction, PlayerItem } from './player.types';

const PLAYER = '[PLAYER]';

export function createPlayerAction<T>(
  action: ActionCreatorWithPayload<PayloadPlayerAction<T>, string>,
  name: PayloadPlayerAction<T>['name'],
  value: PayloadPlayerAction<T>['value']
) {
  return action({
    name,
    value,
  });
}

export const randomPlayersPosition = createAction(`${PLAYER} randomize`);

export function createAllPlayersAction<T>(
  action: ActionCreatorWithPayload<PayloadAllPlayerAction<T>, string>,
  players: PlayerItem[],
  resolveValue: (player: PlayerItem, index: number) => T
) {
  const playersPayloads = players.reduce<PayloadAllPlayerAction<T>>((acc, player, index) => {
    return [
      ...acc,
      {
        name: player.name,
        value: resolveValue(player, index),
      },
    ];
  }, []);

  return action(playersPayloads);
}
