type SongData = {
  id: number;
  name: string;
  displayName: string;
  mediaFormat: string;
};

type SongIdData = string[];

export function isSongData(data: any): data is SongData {
  if (
    typeof data === 'object' &&
    'name' in data &&
    'id' in data &&
    'mediaFormat' in data &&
    'displayName' in data
  ) {
    return true;
  }

  return false;
}

export function isSongIdData(data: any): data is SongIdData {
  if (Array.isArray(data) && data.every((item) => typeof item === 'string')) {
    return true;
  }
  return false;
}

export default SongData;
