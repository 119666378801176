import styles from './media-player.module.scss';
import PLAYERS from '../../config/players';

import MediaPlayerController from '../molecules/media-player-controller';

export default function MediaPlayer() {
  return (
    <div className={styles.mediaPlayer}>
      {PLAYERS.map((player, index) => (
        <MediaPlayerController
          key={`media-player-${index}`}
          className={styles.mediaPlayerController}
          data={player}
          name={`WARSTWA ${index + 1}`}
        />
      ))}
    </div>
  );
}
