import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as ShuffleIcon } from '../../../assets/img/shuffle.svg';
import { randomPlayersPosition } from '../../../redux/player/player.actions';

import Button, { ButtonProps } from '../button';

export default function ShuffleButton({
  className = '',
  disabled,
}: Pick<ButtonProps, 'className' | 'disabled'>) {
  const dispatch = useDispatch();

  const randomize = useCallback(() => {
    dispatch(randomPlayersPosition());
  }, [dispatch]);

  return (
    <Button className={className} onClick={randomize} disabled={disabled}>
      <ShuffleIcon />
    </Button>
  );
}
