import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';

import styles from './map-widget.module.scss';
import ModalContext from '../../contexts/modal.context';
import { getCurrentSongId } from '../../redux/playlist/playlist.selectors';

import MapModal from '../modals/map-modal';
import MapButton from './map-button';

export type MapWidgetProps = {
  children?: string;
  onClick?: () => void;
  className?: string;
};

export default function MapWidget({ children, onClick, className }: MapWidgetProps) {
  const isNotItemSelected = useSelector(getCurrentSongId) === null;
  const { addModal } = useContext(ModalContext);

  const showMap = useCallback(() => {
    addModal(<MapModal />);
  }, [addModal]);

  return (
    <div className={`${styles.mapWidget} ${className}`}>
      <MapButton onClick={onClick || showMap} disabled={isNotItemSelected}>
        {children}
      </MapButton>
    </div>
  );
}
