import { createAction } from '@reduxjs/toolkit';

import FetchError from '../../types/fetch-error.type';
import { FETCH_DATA, FETCH_ERROR, FETCH_SUCCESS } from '../api/api.actions';

export const PLAYLIST = '[PLAYLIST]';

export const requestSongs = createAction(`${PLAYLIST} requestSongs`);
export const requestSingleSong = createAction(
  `${PLAYLIST} requestSingleSong`,
  (id: string, count: number) => {
    return {
      payload: id,
      meta: {
        count,
      },
    };
  }
);

export const fetchSongs = createAction(`${PLAYLIST} ${FETCH_DATA} list`);
export const fetchSongsSuccess = createAction<any>(`${PLAYLIST} ${FETCH_SUCCESS} list`);
export const fetchSongsFail = createAction<FetchError>(`${PLAYLIST} ${FETCH_ERROR} list`);

export const fetchSingleSongs = createAction(`${PLAYLIST} ${FETCH_DATA} single`);
export const fetchSingleSongsSuccess = createAction<any>(`${PLAYLIST} ${FETCH_SUCCESS} single`);
export const fetchSingleSongsFail = createAction<FetchError>(`${PLAYLIST} ${FETCH_ERROR} single`);
