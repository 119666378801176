import { Middleware } from 'redux';
import axios from 'axios';

import { FETCH_DATA } from './api.actions';
import { API_URL } from '../../config/endpoints';
import { addLoader } from '../loaders/loaders.slice';

const apiMiddleware: Middleware = (state) => (next) => async (action) => {
  const { dispatch } = state;
  const { type, payload, meta } = action;

  if ((type as string).includes(FETCH_DATA)) {
    try {
      dispatch(addLoader(meta.entity));
      const response = await axios({
        ...payload.request,
        url: `${API_URL}${payload.request.url}`,
      });

      if (response.status === 200 || response.status === 201) {
        dispatch({
          type: payload.actionSuccess,
          payload: response.data,
          meta,
        });
      } else {
        dispatch({
          type: payload.actionError,
          payload: {
            code: response.status,
            message: response.statusText,
          },
          meta,
        });
      }
    } catch (err) {
      dispatch({
        type: payload.actionError,
        payload: {
          code: 0,
          message: err,
        },
        meta,
      });
    }
  } else {
    next(action);
  }
};

export default apiMiddleware;
