import { useEffect, useMemo, useState } from 'react';

export default function useBreakpoint(breakpoint: number) {
  const mediaQueryList = useMemo(() => matchMedia(`(max-width: ${breakpoint}px)`), [breakpoint]);
  const [matches, setMatches] = useState(mediaQueryList.matches);

  useEffect(() => {
    const handleChange = (event: MediaQueryListEvent) => setMatches(event.matches);

    mediaQueryList.addEventListener('change', handleChange);

    return () => {
      mediaQueryList.removeEventListener('change', handleChange);
    };
  }, [mediaQueryList]);

  return matches;
}
