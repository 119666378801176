export const API = '[API]';

export const FETCH_DATA = `${API} fetch`;
export const FETCH_SUCCESS = `${API} success`;
export const FETCH_ERROR = `${API} error`;

export const fetchData = (payload: any, meta?: Record<string, any>) => ({
  type: FETCH_DATA,
  payload,
  meta,
});
