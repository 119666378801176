import {
  PayloadAllPlayerAction,
  PayloadPlayerAction,
  PlayerItem,
  PlayerState,
} from './player.types';
import { FilterByFieldType, FilterKeysByFieldType } from '../../types/utility.type';

export const setFlag = (
  state: FilterByFieldType<PlayerState, boolean>,
  flag: FilterKeysByFieldType<PlayerState, boolean>,
  value: boolean
) => {
  state[flag] = value;
};

export const arePlayersArrayEqual = (playersA: PlayerItem[], playersB: PlayerItem[]) => {
  return playersA.every((playerA) =>
    Object.entries(playerA).every(([key, value]) => {
      const playerB = playersB.find((item) => item.name === playerA.name);

      if (playerB) {
        return playerB[key as keyof PlayerItem] === value;
      } else {
        return false;
      }
    })
  );
};

export const setEntityParam = (
  players: Record<string, any>[],
  payload: PayloadPlayerAction<any>,
  name: keyof typeof players[0]
) => {
  const index = players.findIndex((player) => player.name === payload.name);

  if (index !== -1) {
    players[index][name] = payload.value;
  }
};

export const setAllEntitiesParam = (
  players: Record<string, any>[],
  payload: PayloadAllPlayerAction<any>,
  name: keyof typeof players[0]
) => {
  payload.forEach((item) => {
    setEntityParam(players, item, name);
  });
};
