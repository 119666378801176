import React, { useContext } from 'react';
import ModalContext from '../../contexts/modal.context';

function ModalContainer() {
  const { modals, removeModal } = useContext(ModalContext);

  const handleClose = (id: string) => {
    removeModal(id);
  };

  return (
    <>
      {Object.entries(modals).map(([id, modal]) =>
        React.cloneElement(modal, { key: id, onClose: () => handleClose(id) })
      )}
    </>
  );
}

export default ModalContainer;
