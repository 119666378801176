import { RootState } from '../store';
import { PlayerItem } from './player.types';

const findPlayer = (players: PlayerItem[] | null, name: string) =>
  players?.find((player) => player.name === name);

export const getIsAuto = (state: RootState) => state.player.isAuto;
export const getIsPlaying = (state: RootState) =>
  state.player.players.some((player) => player.isPlaying);
export const getPlayers = (state: RootState) => state.player.players;
export const getPlayer = (state: RootState) => (name: string) =>
  findPlayer(getPlayers(state), name);

export const getOldPlayers = (state: RootState) => state.player.oldPlayers;
export const getOldPlayer = (state: RootState) => (name: string) =>
  findPlayer(getOldPlayers(state), name);

export const getPlayerDiff = (state: RootState) => (name: string) => {
  const player = getPlayer(state)(name);
  const oldPlayer = getOldPlayer(state)(name);

  if (player) {
    if (oldPlayer) {
      const shit = Object.entries(player).reduce<Partial<PlayerItem>>((acc, [key, value]) => {
        if (value !== oldPlayer[key as keyof PlayerItem]) {
          //@ts-ignore
          acc[key as keyof PlayerItem] = value;
        }
        return acc;
      }, {});

      return shit;
    } else {
      return player;
    }
  }
};
