//@ts-nocheck
import defaultVolumeControllerConfig from './volume-controller';

window.appSettings = {
  ...window.appSettings,
  volumeControllerConfig: { ...defaultVolumeControllerConfig },
};

const volumeControllerConfig = window.appSettings.volumeControllerConfig;

export default volumeControllerConfig;
