import { useSelector } from 'react-redux';

import styles from './map-song-list.module.scss';
import withSongListData from '../hoc/withSongListData';
import { SongListProps } from './song-list';
import { getIsAuto } from '../../redux/player/player.selectors';
import translations from '../../locales/pl.json';

import MapSongListElement from '../atoms/map-song-list-element';

export type MapSongListProps = {
  onItemMouseOver?: (id: number) => void;
} & SongListProps;

function MapSongList({
  className,
  items,
  loading,
  onItemClicked = () => {},
  value,
  onItemMouseOver = () => {},
}: MapSongListProps) {
  const isAuto = useSelector(getIsAuto);

  if (loading) {
    return <p>loading</p>;
  }

  return (
    <div className={`${styles.mapSongListWrapper} ${className}`}>
      <h4 className={styles.title}>Lista utworów</h4>
      <ol className={styles.songList}>
        {items && items.length > 0 ? (
          items.map((item) => (
            <MapSongListElement
              key={`map-song-list-element-${item.id}`}
              className={styles.songListElement}
              active={value === item.id}
              onClick={() => onItemClicked(item.id)}
              onMouseEnter={() => onItemMouseOver(item.id)}
              onMouseLeave={() => onItemMouseOver(0)}
              disabled={isAuto}
              tooltip={isAuto ? translations['tooltip.songListElement.disabled'] : undefined}
            >
              {item.displayName}
            </MapSongListElement>
          ))
        ) : (
          <p>Niestety lista jest pusta!</p>
        )}
      </ol>
    </div>
  );
}

export default withSongListData(MapSongList);
