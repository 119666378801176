import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isLoading } from '../../redux/loaders/loaders.selectors';
import { PLAYLIST } from '../../redux/playlist/playlist.actions';
import { getCurrentSongId, getSongs } from '../../redux/playlist/playlist.selectors';
import { setCurrentSongId } from '../../redux/playlist/playlist.slice';
import { SongListProps } from '../molecules/song-list';

export default function withSongListData<Props extends SongListProps>(Component: React.FC<Props>) {
  return function SongListData(props: Props) {
    const songs = useSelector(getSongs);
    const currentSongId = useSelector(getCurrentSongId);
    const isListLoading = useSelector(isLoading)(PLAYLIST);
    const dispatch = useDispatch();

    let songsToSort = [...songs];
    songsToSort.sort((firstSong, secondSong) => firstSong.id - secondSong.id);

    const setCurrentItem = useCallback(
      (id) => {
        dispatch(setCurrentSongId(id));
      },
      [dispatch]
    );

    return (
      <Component
        items={songsToSort}
        value={currentSongId}
        onItemClicked={setCurrentItem}
        loading={isListLoading}
        {...props}
      />
    );
  };
}
