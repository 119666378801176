import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { browserName } from 'react-device-detect';

import styles from './app.module.scss';
import { requestSongs } from '../redux/playlist/playlist.actions';
import isBrowserNotSupported from '../utility/check-browser';

import SongList from './molecules/song-list';
import Header from './organisms/header';
import MediaPlayer from './organisms/media-player';
import Notifications from './organisms/notifications';
import ModalContainer from './organisms/modal-container';
import withGlobalContext from './hoc/withGlobalContext';
import BackgroundVideo from './atoms/background-video';
import Toolbar from './organisms/toolbar';
import MapWidget from './atoms/map-widget';
import LogoList from './molecules/logo-list';
import modalContext from '../contexts/modal.context';
import NotSupportedModal from './modals/not-supported-modal';

export type AppProps = {
  onClick?: () => void;
};

function App({ onClick }: AppProps) {
  const dispatch = useDispatch();
  const { addModal } = useContext(modalContext);

  useEffect(() => {
    dispatch(requestSongs());
  }, [dispatch]);

  useEffect(() => {
    if (isBrowserNotSupported(browserName)) {
      addModal(<NotSupportedModal />);
    }
  }, [addModal]);

  return (
    <div className={styles.app} onClick={onClick}>
      <BackgroundVideo />
      <div className={styles.container}>
        <ModalContainer />
        <Header />
        <div className={styles.content}>
          <MediaPlayer />
          <div className={styles.toolbarWrapper}>
            <Toolbar />
            <SongList />
          </div>
          <LogoList className={styles.logoList} />
          <MapWidget>Mapa</MapWidget>
          <Notifications />
        </div>
      </div>
    </div>
  );
}

export default withGlobalContext(App);
