import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsAuto } from '../../../redux/player/player.selectors';
import { setAuto } from '../../../redux/player/player.slice';

import ToggleButton, { ToggleButtonProps } from '../toggle-button';

export default function AutoButton({ className = '' }: Pick<ToggleButtonProps, 'className'>) {
  const isAuto = useSelector(getIsAuto);
  const dispatch = useDispatch();

  const setIsAuto: ToggleButtonProps['onClick'] = useCallback(
    (value) => dispatch(setAuto(value)),
    [dispatch]
  );

  return (
    <ToggleButton className={className} onClick={setIsAuto} value={isAuto}>
      Auto
    </ToggleButton>
  );
}
