import { useDispatch, useSelector } from 'react-redux';

import styles from './notifications.module.scss';
import { getNotifications } from '../../redux/notifications/notifications.selectors';
import { removeNotification } from '../../redux/notifications/notifications.slice';

import Notification from '../molecules/notification';

export default function Notifications() {
  const notifications = useSelector(getNotifications);
  const dispatch = useDispatch();

  return (
    <div className={styles.notificationsWrapper}>
      {notifications.map(({ id, text, type }) => (
        <Notification
          key={`notification-${id}`}
          id={id}
          type={type}
          text={text}
          onClose={() => dispatch(removeNotification({ id }))}
        />
      ))}
    </div>
  );
}
