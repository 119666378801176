import PlayerController from '../types/player-controller.type';

const players: PlayerController[] = [
  {
    name: 'A',
  },

  {
    name: 'B',
  },

  {
    name: 'C',
  },

  {
    name: 'D',
  },
];

export const playersConfig = {
  shortFade: 0.025, //seconds
  mediumFade: 0.25, //seconds
  longFade: 2, //seconds
};

export default players;
