import styles from './notification.module.scss';
import { NotificationData } from '../../redux/notifications/notifications.types';

import Button from '../atoms/button';

export type NotificationProps = {
  onClose: (id: string) => void;
  className?: string;
} & NotificationData;

export default function Notification({
  id,
  type,
  text,
  onClose,
  className = '',
}: NotificationProps) {
  return (
    <div className={`${styles.notification} ${styles[type]} ${className}`}>
      <div className={styles.toolbar}>
        <Button onClick={() => onClose(id)} className={styles.button} noInnerWrapper noHover>
          x
        </Button>
      </div>
      <div className={styles.content}>
        <p className={styles.contentParagraph}>{text}</p>
      </div>
    </div>
  );
}
