import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsAuto, getPlayer, getPlayerDiff } from '../redux/player/player.selectors';
import {
  setAuto,
  setPlayerDuration,
  setPlayerPlaying,
  setPlayerVolume,
  updateOldPlayers,
} from '../redux/player/player.slice';
import { createPlayerAction } from '../redux/player/player.actions';
import { showNotification } from '../redux/notifications/notifications.actions';
import MixerContext from '../contexts/mixer.context';
import MediaPlayer from '../utility/media-player';
import { getCurrentSongId } from '../redux/playlist/playlist.selectors';
import { getFadeTimeByName } from '../redux/volume-controller/volume-controller.selectors';
import mediaErrorMessages from '../locales/media-error.pl';
import { MediaWarning } from '../types/media.types.ts';
import mediaWarningMessage from '../locales/media-warning';

const MAX_WAITING_COUNTER_VALUE = 5;

export default function useMediaPlayer(name: string) {
  const player = useSelector(getPlayer)(name);
  const playerDiff = useSelector(getPlayerDiff)(name);
  const isAuto = useSelector(getIsAuto);
  const canPlay = useSelector(getCurrentSongId) !== null;
  const fadeTime = useSelector(getFadeTimeByName)(name);

  const [currentPosition, setCurrentPosition] = useState(0);
  const [currentVolume, setCurrentVolume] = useState(-1);
  const dispatch = useDispatch();
  const audioElement = useRef<HTMLAudioElement>(null);
  const audioContext = useContext(MixerContext);
  const mediaPlayer = useRef<MediaPlayer>();
  const waitingCounter = useRef(0);

  const audioElementRef = audioElement.current;

  const setPlaying = useCallback(
    (value) => dispatch(createPlayerAction(setPlayerPlaying, name, value)),
    [dispatch, name]
  );

  const setVolume = useCallback(
    (value) => {
      if (isAuto) {
        dispatch(setAuto(false));
      }
      dispatch(createPlayerAction(setPlayerVolume, name, value));
    },
    [dispatch, name, isAuto]
  );

  const handleTimeUpdate = () => {
    if (audioElementRef) {
      setCurrentPosition(audioElementRef.currentTime);
    }
  };

  const handleDurationChange = () => {
    if (audioElementRef) {
      dispatch(createPlayerAction(setPlayerDuration, name, audioElementRef.duration));
    }
  };

  const handleError = () => {
    if (!audioElementRef || !audioElementRef.error) {
      //eslint-disable-next-line no-console
      console.error(audioElementRef?.error);
      return;
    }

    let message = '';

    if (player?.name) {
      message = `Warstwa ${player.name}: `;
    }

    message = `${message}${mediaErrorMessages[audioElementRef.error.code]}`;

    dispatch(showNotification({ type: 'error', text: message }));
  };

  const handleWaiting = () => {
    waitingCounter.current++;

    if (waitingCounter.current >= MAX_WAITING_COUNTER_VALUE) {
      dispatch(
        showNotification({
          type: 'warning',
          text: mediaWarningMessage[MediaWarning.SlowConnection],
        })
      );
      waitingCounter.current = 0;
    }
  };

  const restoreVolumeAfterAuto = useCallback(() => {
    const _currentVolume = mediaPlayer.current?.getCurrentVolume();

    if (_currentVolume) {
      dispatch(createPlayerAction(setPlayerVolume, name, _currentVolume));
      setCurrentVolume(-1);
    }
  }, [dispatch, name]);

  //on mount
  useEffect(() => {
    if (audioElementRef && audioContext) {
      mediaPlayer.current = new MediaPlayer(audioContext, audioElementRef);
      mediaPlayer.current.onVolumeChange((value) => setCurrentVolume(value));
    }
  }, [audioElementRef, audioContext]);

  //on player update
  useEffect(() => {
    if (playerDiff && Object.keys(playerDiff).length > 0) {
      if (playerDiff.src) {
        if (isAuto) {
          dispatch(setAuto(false));
        }
        waitingCounter.current = 0;
      }

      if (playerDiff.isPlaying === false && isAuto) {
        mediaPlayer.current?.setIsAuto(false);
        restoreVolumeAfterAuto();
      }
      mediaPlayer.current?.change(playerDiff);
      dispatch(updateOldPlayers());
    }
  }, [playerDiff, dispatch, isAuto, restoreVolumeAfterAuto]);

  useEffect(() => {
    mediaPlayer.current?.setIsAuto(isAuto);

    if (!isAuto) {
      restoreVolumeAfterAuto();
    }
  }, [isAuto, dispatch, name, restoreVolumeAfterAuto]);

  useEffect(() => {
    mediaPlayer.current?.setFadeTime(fadeTime);
  }, [fadeTime]);

  const audioProps = {
    ref: audioElement,
    onTimeUpdate: handleTimeUpdate,
    onDurationChange: handleDurationChange,
    onError: handleError,
    onWaiting: handleWaiting,
  };

  return {
    setPlaying,
    setVolume,
    position: currentPosition,
    duration: player?.duration,
    isPlaying: player?.isPlaying,
    volume: isAuto && currentVolume !== -1 ? currentVolume : player?.volume,
    canPlay,
    audioProps,
    isAuto,
  };
}
