import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './map-modal.module.scss';
import { getCurrentSongId } from '../../redux/playlist/playlist.selectors';
import { setCurrentSongId } from '../../redux/playlist/playlist.slice';
import { getIsPlaying, getPlayers } from '../../redux/player/player.selectors';

import Modal, { ModalProps } from '../templates/modal';
import MapWidget from '../atoms/map-widget';
import Footer from '../organisms/footer';
import Header from '../organisms/header';
import InteractiveMap, { InteractiveMapProps } from '../organisms/interactive-map';
import MapSongList from '../molecules/map-song-list';
import LogoList from '../molecules/logo-list';
import { setPlayersPlaying } from '../../redux/player/player.slice';
import { createAllPlayersAction } from '../../redux/player/player.actions';

export type MapModalProps = Omit<ModalProps, 'children'>;

export default function MapModal({ onClose, ...props }: MapModalProps) {
  const currentSongId = useSelector(getCurrentSongId);
  const players = useSelector(getPlayers);
  const isPlaying = useSelector(getIsPlaying);
  const [currentHoveredPointId, setCurrentHoveredPointId] = useState(0);
  const dispatch = useDispatch();

  let mapValue: number | undefined;

  const handleMapClick: InteractiveMapProps['onClick'] = useCallback(
    (id) => {
      dispatch(setCurrentSongId(id));

      if (!isPlaying) {
        dispatch(createAllPlayersAction(setPlayersPlaying, players, () => true));
      }
    },
    [dispatch, isPlaying, players]
  );
  const handleMapMouseOver: InteractiveMapProps['onMouseOver'] = useCallback((id) => {
    setCurrentHoveredPointId(id);
  }, []);

  if (currentHoveredPointId > 0) {
    mapValue = currentHoveredPointId;
  } else if (currentSongId !== null) {
    mapValue = currentSongId;
  }

  return (
    <Modal className={styles.mapModal} {...props}>
      <Header mapWidgetProps={{ onClick: onClose }} />
      <MapWidget onClick={onClose}>Metrofonia</MapWidget>
      <div className={styles.content}>
        <div className={styles.map}>
          <InteractiveMap
            value={mapValue}
            onMouseOver={handleMapMouseOver}
            onClick={handleMapClick}
          />
        </div>
        <div className={styles.list}>
          <MapSongList
            value={mapValue}
            onItemMouseOver={handleMapMouseOver}
            onItemClicked={handleMapClick}
          />
        </div>
        <LogoList className={styles.logoList} />
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </Modal>
  );
}
