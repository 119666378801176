import styles from './modal.module.scss';

export type ModalProps = {
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
};

export default function Modal({ children, className = '' }: ModalProps) {
  return <div className={`${styles.modal} ${className}`}>{children}</div>;
}
