import { linearChangeAudioParam, ChangeAudioParamProps } from './exponential-change-audio-param';

type FadeProps = {
  beforeEnd: () => void;
} & ChangeAudioParamProps;

export function fadeIn({ audioParam, value, time, beforeEnd, audioContext }: FadeProps) {
  if (audioParam) {
    audioParam.cancelScheduledValues(audioContext.currentTime);
    audioParam.value = 0.01;
    beforeEnd();
    linearChangeAudioParam({ audioParam, value, time, audioContext });
  }
}

export function fadeOut({ audioParam, time, value, beforeEnd, audioContext }: FadeProps) {
  if (audioParam) {
    audioParam.cancelScheduledValues(audioContext.currentTime);
    linearChangeAudioParam({ audioParam, value, time, audioContext });
    setTimeout(() => {
      beforeEnd();
    }, time * 1000);
  }
}
