import { FooterElementProps } from '../components/atoms/footer/footer-element';

export type Authors = {
  column: 'left' | 'center' | 'right';
} & Pick<FooterElementProps, 'title' | 'content' | 'reverse'>;

const authors: Authors[] = [
  {
    column: 'left',
    title: 'Łukasz Zięba',
    content:
      'realizacja nagrań studyjnych i terenowych, produkcja, montaż, miks i mastering, kontrabas (1, 9, 10, 12), elektronika (1 – 3, 5 - 12)',
  },
  {
    column: 'left',
    title: 'Michał Lazar',
    content:
      'aranżacja muzyczna, gitara klasyczna (4, 7, 8, 11), kieliszek (1), śpiew (2-4), kalimba (3, 5), Wurli (4), fortepian (2, 6-8), dzwonki (6, 9, 10), tin whistle',
  },
  {
    column: 'left',
    title: 'Wiktoria Krużyk',
    content: 'klarnet (1, 12)',
  },
  {
    column: 'left',
    title: 'Piotr Łosiak',
    content: 'software',
  },
  {
    column: 'left',
    title: 'Patrycja Dulnik',
    content: 'design',
  },
  {
    column: 'left',
    title: 'Adrien Cognac',
    content: 'wideo',
  },
  {
    column: 'right',
    title: 'Maja Piątkowska',
    content: 'taniec ręki',
  },
  {
    column: 'right',
    title: 'Podziękowania dla:',
    content:
      'Wioleta\xa0Żochowska, Wojtek\xa0Błażejczyk, prof.\xa0Witold\xa0Osiński, Krzysztof\xa0Pietraszewski, Dom\xa0Kultury\xa0Praga oraz Technikum\xa0Kinematograficzno – Komputerowe w\xa0Warszawie',
    reverse: true,
  },
];

export default authors;
