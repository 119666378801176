import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SongData from '../../types/song.type';
import PlaylistState from './playlist.types';

const initialState: PlaylistState = {
  items: [],
  current: null,
};

const playlistSlice = createSlice({
  initialState,
  name: 'playlist',
  reducers: {
    addSong(state, action: PayloadAction<SongData>) {
      state.items.push(action.payload);
    },
    setCurrentSongId(state, action: PayloadAction<number>) {
      state.current = action.payload;
    },
    clearSongs(state) {
      state.items = [];
    },
  },
});

export const { addSong, setCurrentSongId, clearSongs } = playlistSlice.actions;
export default playlistSlice.reducer;
