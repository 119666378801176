class Mixer {
  audioContext;
  private limiterNode;

  constructor(audioContext: AudioContext) {
    this.audioContext = audioContext;
    this.limiterNode = new DynamicsCompressorNode(audioContext, {
      threshold: 0,
      knee: 0,
      ratio: 20,
      release: 0.01,
    });
    this.limiterNode.connect(this.audioContext.destination);
  }

  get inputNode() {
    return this.limiterNode;
  }

  resumeAudioContext() {
    if (this.audioContext.state !== 'running') {
      this.audioContext.resume();
    }
  }
}

export default Mixer;
